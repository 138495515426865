var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _c("Navbar"),
      _c("main", [
        _c(
          "div",
          { staticStyle: { left: "50%", right: "0", "margin-top": "10%" } },
          [
            _c("v-progress-circular", {
              attrs: { size: 30, color: "primary", indeterminate: "" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="dev-onboard">
    <Navbar />
    <main>
      <div style="left: 50%; right: 0; margin-top: 10%">
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
      </div>
    </main>
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import postLogin from "@/mixins/postLogin";
import Navbar from "@/components/Navbar/onboarding_navbar";
import mixPanel from "@/mixins/mixPanel";
import { changeUserType } from "@/api/team";
import { async } from "validate.js";

export default {
  mixins: [postLogin, mixPanel],
  components: {
    Navbar,
  },
  data: () => ({
    code: "",
    name: "",
    email: "",
    linkedin_id: "",

    firstName: "",
    lastname: "",
  }),
  created() {
    if (!this.registrationToken) {
      this.teamLeadProfileCode();
    }
    if (this.registrationToken) {
      this.teamMemberProfileCode();
    }
    if (this.linkedinLogin == "login") {
      this.linkedinLoginProfileCode();
    }
  },
  mounted() { },
  methods: {
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserReferralCode",
      "setCustomerType",
    ]),

    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devOnboarding", [
      "setRegistrationToken",
      "setLinkedinProfileUrl",
    ]),
    async updateUserType(type) {
      this.loader = true;
      // alert(type)
      await changeUserType(type)
        .then((response) => {
          // alert(JSON.stringify(response))
          console.log("updateUserType - in => " + type + " :: ", response);
          if (response.data.status == 1) {
            const { user, nylas } = response.data.data;
            //console.log(this.$router)

            // console.log("User Type", nylas);
            this.updateLoginUser(user);
            if (type == 1) {
              // this.$router.push({ name: "linkedin_profile" });
            } else {
              // this.$router.push({ name: "start_project" });
            }
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    async teamLeadProfileCode() {
      if (this.$route.query.code) {
        const params = this.$route.query.code;
        const linkedin_id = this.$route.query.linkedin_id;
        let referralCode = this.referralCode;
        let user_type = this.$route.query.user_type || this.customerType;

        let payload = {};

        if (this.$route.query.saved) {
          this.firstName = this.$route.query.firstName;
          this.lastName = this.$route.query.lastName;

          payload.firstName = this.firstName;
          payload.lastName = this.lastName;
          payload.saved = this.$route.query.saved;
          payload.email = this.$route.query.email;


          if (user_type == "" || user_type == null || user_type == undefined) {
            this.isError = true;
            alert(
              "User category not detected. \nKindly choose if you are a founder or an engineer to continue"
            );

            document.location.href = `${this.$Website}`;
          }
        }

        this.setCustomerType(this.customerType);

        if (referralCode) {
          payload.referralCode = referralCode;
        }
        payload.user_type = user_type;

        await Axios.post(
          this.$baseUrl + `/onboarding/linkedin/sign_in/${params}`,
          payload
        )
          .then((response) => {
            //console.log({ response });
            //alert('yes')
            if (response.data.status == 1) {
              let { user, token, team, vanity_name, founders } =
                response.data.data;
              //  console.log({ user });
              user.token = token;
              this.setLogInUser(user);

              localStorage.setItem(
                "grupa_user_founder",
                JSON.stringify(founders)
              );
              //
              this.setLinkedinProfileUrl(linkedin_id);
              if (user.user_type == 1) {
                if (team) {
                  this.teamActivePage(user, team);
                } else {
                  // if (user.activation_level >= 2) {
                  //   this.$router.push({ name: "team_option" });
                  // } else {
                  //   this.$router.push({ name: "linkedin_profile" });
                  // }
                  this.updateTeam(token, user);
                }
              } else if (user.user_type == 2 || !user.user_type) {
                // alert('2')
                this.$router.push({ name: "user_type" });
                // this.$router.push({ name: "start_project" });
              }
              // Set event for expert
              let event =
                user.user_type == 1
                  ? "LinkedinSignUp"
                  : "LinkedinSignUpFounder";
              user.event = event;
              user.vanity_name = vanity_name;
              this.mixPanelCreateUser(user);
              this.setUserReferralCode(null);
            } else if (response.data.status == 2) {
              console.log(response.data.data);

              this.email = response.data.data.email;
              this.name = response.data.data.name;
              this.code = response.data.data.code;
              this.linkedin_id = response.data.data.linkedin_id;

              this.$router.push({
                name: "linkedin_new_user_verification",
                query: {
                  name: response.data.data.name,
                  email: response.data.data.email,
                  linkedin_id: response.data.data.linkedin_id,
                  code: response.data.data.code,
                },
              });
            } else {
              this.setErrorMsg("User Authetication Failed-1");
              this.mixPanelTrack({
                event: "LinkedinSignUpFailed",
                email: null,
                message: "User Authetication Failed-1",
              });
              this.$router.push({ name: "sign_up" });
            }
          })
          .catch((error) => {
            alert("error");
            console.log(error);
            this.setErrorMsg("User Authetication Failed-1.1");
            this.mixPanelTrack({
              event: "LinkedinSignUpFailed",
              email: null,
              message: "User Authetication Failed-1.1",
            });
            // this.$router.push({ name: "sign_up" });
          });
      }
    },
    async teamMemberProfileCode() {
      const link = this.registrationToken;
      const payload = { link };
      if (this.$route.query.code) {
        const params = this.$route.query.code;
        await Axios.post(
          this.$baseUrl +
          `/dev/onboarding/linkedin/get-profile-invited/${params}`,
          payload
        )
          .then((response) => {
            if (response.data.status == 1) {
              let { user, token, team, vanity_name } = response.data.data;
              user.token = token;
              this.setLogInUser(user);
              this.setLinkedinProfileUrl(vanity_name);
              if (user.user_type == 1) {
                if (team && user) {
                  this.teamActivePage(user, team);
                  this.setRegistrationToken(null);
                } else {
                  this.$router.push({
                    name: "join_team",
                    params: { reg_token: link },
                  });
                }
              } else {
                // this.setErrorMsg(
                //   "You currently have a client account on the platform, kindly sigin here!!"
                // );
                this.$router.push({ name: "login" });
              }
              user.event = "LinkedinLoginJoinTeam";
              user.vanity_name = vanity_name;
              this.mixPanelCreateUser(user);
            } else {
              this.setErrorMsg("User Authetication Failed-2");
              this.$router.push({
                name: "join_team",
                params: { reg_token: link },
              });
            }
          })
          .catch((error) => {
            this.setErrorMsg("User Authetication Failed-2.1");
            this.$router.push({
              name: "join_team",
              params: { reg_token: link },
            });
          });
      }
    },
    async linkedinLoginProfileCode() {
      if (this.$route.query.code) {
        const params = this.$route.query.code;
        await Axios.get(
          this.$baseUrl + `/onboarding/linkedin/sign_in/${params}`
        )
          .then((response) => {
            if (response.data.status == 1) {
              let { user, token, vanity_name } = response.data.data;
              user.token = token;
              this.setLinkedinProfileUrl(vanity_name);
              this.setLogInUser(user);
              if (user.user_type == 1) {
                const { team } = response.data.data;
                if (team) {
                  this.teamActivePage(user, team);
                } else {
                  this.$router.push({ name: "login" });
                }
              } else if (user.user_type == 2 || user.user_type == 5) {
                this.$router.push({ name: "login" });
              } else {
                this.$router.push({ name: "user_type" });
              }
            } else {
              this.setErrorMsg("User Authetication Failed-0");
              this.$router.push({ name: "sign_up" });
            }
          })
          .catch((error) => {
            this.setErrorMsg("User Authetication Failed-0.1");
            this.$router.push({ name: "sign_up" });
          });
      }
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      localStorage.setItem("grupa_user", JSON.stringify(user));
      this.setAutheticatedUser(payload);
    },
    updateTeam(token) {
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      Axios.get(this.$baseUrl + `/onboarding/set/usertype/1`, header)
        .then((response) => {
          if (response.data.status == 1) {
            const { user } = response.data.data;
            this.updateLoginUser(user);
            if (user.activation_level >= 2) {
              this.$router.push({ name: "team_option" });
            } else {
              this.$router.push({ name: "linkedin_profile" });
            }
          }
        })
        .catch((error) => { });
    },
    updateLoginUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
    },
  },
  computed: {
    ...mapState("devOnboarding", ["registrationToken"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("userManagement", [
      "linkedinLogin",
      "referralCode",
      "customerType",
      "currentUser",
    ]),
  },
};
</script>
<style scoped>

</style>
